<template>
  <a :href="link.link" target="_blank" class="d-block">
    <div class="media border border-primary rounded">
      <img
        class="ml-2 mr-3 my-1"
        style="
          min-width: 45px;
          max-width: 45px;
          min-height: 45px;
          max-height: 45px;
        "
        :src="link.icon"
        alt=""
      />

      <div class="media-body">
        <span class="" style="max-height: 45px">{{ link.title }}</span>
        <font-awesome-icon
          :icon="['fas', 'external-link-alt']"
          size="xs"
          class="m-1 float-right"
        ></font-awesome-icon>

        <p
          class="d-none d-sm-block mb-1 small text-muted text-decoration-none"
          style="min-height: 24px"
        >
          {{ link.desc }}
        </p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'linkCard',
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
