<template>
  <router-link
    v-if="isRegistrationOpen"
    to="/register"
    class="btn btn-secondary btn-outline-primary"
  >
    <slot>Register Today!</slot>
  </router-link>
</template>

<script>
import regFunctions from '@/commons/registrationFunctions'

export default {
  name: 'registrationButton',
  computed: {
    isRegistrationOpen() {
      return regFunctions.isRegistrationOpen()
    },
  },
}
</script>

<style scoped></style>
