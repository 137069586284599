<template>
  <a
    class="btn btn-secondary btn-outline-primary"
    href="http://www.treasuretees.com/haverhill_hammers_wrestling"
    target="_blank"
  >
    Team Store
    <font-awesome-icon
      :icon="['fas', 'external-link-alt']"
      class="ml-4"
    ></font-awesome-icon>
  </a>
</template>

<script>
export default {
  name: 'teamStoreButton',
}
</script>

<style scoped></style>
