<template>
  <div>
    <div class="jumbotron jumbotron-fluid mb-0 py-2">
      <div class="d-flex flex-sm-row flex-column" style="gap: 2px">
        <img
          class="rounded mx-auto d-block mt-0 mb-1"
          style="max-width: 100%; max-height: 475px"
          src="/img/2023-24_ElementarySchoolTeam.jpg"
          alt="2018-2019 Team"
        />
        <img
          class="rounded mx-auto d-block mt-0 mb-1"
          style="max-width: 100%; max-height: 475px"
          src="/img/2023-24_MiddleSchoolTeam.jpg"
          alt="2018-2019 Team"
        />
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-lg-center">
        <div class="col-lg-7 px-0">
          <team-store-open />
          <free-trial />
          <registration-open />
        </div>

        <div class="col-12 col-lg-5 px-0 order-lg-first">
          <content-box title="Facebook Page" class="mb-3">
            <div class="mb-4">
              For all the latest News and Information, follow us on Facebook
            </div>

            <div id="fb-root" />

            <div
              class="fb-page"
              data-href="https://www.facebook.com/HaverhillHammersYouthWrestling/"
              data-tabs=""
              data-width="1500"
              data-height="1000"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/HaverhillHammersYouthWrestling/"
                class="fb-xfbml-parse-ignore"
              >
                <a
                  href="https://www.facebook.com/HaverhillHammersYouthWrestling/"
                >
                  Haverhill Hammers Youth Wrestling
                </a>
              </blockquote>
            </div>
          </content-box>

          <content-box title="Useful Links" class="px-0">
            <link-card
              v-for="link in links"
              :key="link.link"
              :link="link"
              class="mb-2"
            />
          </content-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contentBox from '@/components/contentBox'
import linkCard from '@/components/linkCard'
import FreeTrial from '@/components/announcements/freeTrial'
import RegistrationOpen from '@/components/announcements/registrationOpen'
import TeamStoreOpen from '@/components/announcements/teamStoreOpen'

export default {
  name: 'home',
  components: {
    TeamStoreOpen,
    RegistrationOpen,
    FreeTrial,
    contentBox,
    linkCard,
  },
  mounted() {
    let fbPageScript = document.createElement('script')

    fbPageScript.setAttribute('async', null)
    fbPageScript.setAttribute('defer', null)
    fbPageScript.setAttribute('crossorigin', 'anonymous')
    fbPageScript.setAttribute(
      'src',
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v4.0&appId=1744383095818038&autoLogAppEvents=1'
    )

    document.head.appendChild(fbPageScript)
  },
  data() {
    return {
      links: [
        {
          link: 'https://www.usawmwa.org/',
          title: 'Massachusetts Wrestling Association',
          desc: 'Home to the Massachusetts chapter of USA Wrestling.  Providing educational resources for coaches, wrestlers and parents.',
          icon: '/img/links/MWAlogo.jpg',
        },
        {
          link: 'https://www.usawmembership.com/login',
          title: 'USA Wrestling',
          desc: 'Home of USA Wrestling, the governing body for wrestling in the USA.  Provides member registration and educational resources.',
          icon: '/img/links/USAWrestlingLogo.png',
        },
        {
          link: 'http://arena.flowrestling.org/',
          title: 'FLOARENA',
          desc: 'Streaming service for many tournament.  Flo Arena provides information for brackets, bouts and mat assignments.',
          icon: '/img/links/FloWrestlingLogo.jpg',
        },
      ],
    }
  },
}
</script>
