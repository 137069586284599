<template>
  <div class="card col-11 mx-auto my-3 px-0 border border-primary">
    <h5 class="card-header">
      {{ title }}
    </h5>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contentBox',
  props: ['title'],
}
</script>

<style scoped></style>
