export default {
  getSeason() {
    const date = new Date()

    switch (date.getMonth()) {
      // January-July
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return date.getFullYear() - 1

      // August-December
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        return date.getFullYear()
    }
  },
  toString() {
    let season = this.getSeason()
    return season++ + '-' + season
  },
}
