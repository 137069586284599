<template>
  <content-box v-if="showRegistrationAnnouncement" :title="title">
    <h3 class="text-center">Registration is OPEN!!</h3>
    <div class="my-4">
      <p>
        Registration for the
        <span class="font-weight-bold text-primary">{{ season }}</span> season
        is now <span class="font-weight-bold text-primary">OPEN</span>!!
      </p>
      <p>
        Don't miss your chance to join a team that works hard and breeds
        success!
      </p>
      <p>
        Practices start the first week of November and are open to all students,
        Kindergarten through 8th grade.
      </p>
    </div>

    <registration-button></registration-button>
  </content-box>
</template>

<script>
import regFunctions from '@/commons/registrationFunctions'
import seasonFunctions from '@/commons/seasonFunctions'

import ContentBox from '@/components/contentBox'
import RegistrationButton from '@/components/registration/registrationButton'

export default {
  name: 'registrationOpen',
  components: { RegistrationButton, ContentBox },
  computed: {
    showRegistrationAnnouncement() {
      return (
        !regFunctions.isFreeTrialOpen() && regFunctions.isRegistrationOpen()
      )
    },
    season() {
      return seasonFunctions.toString()
    },
    title() {
      return this.season + ' Registration'
    },
  },
}
</script>

<style scoped></style>
