import { render, staticRenderFns } from "./teamStoreButton.vue?vue&type=template&id=0660c86a&scoped=true&"
import script from "./teamStoreButton.vue?vue&type=script&lang=js&"
export * from "./teamStoreButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0660c86a",
  null
  
)

export default component.exports