<template>
  <content-box v-if="false" :title="title">
    <h3 class="text-center">The team store is now OPEN!!</h3>
    <div class="my-4">
      <p>
        Don't miss out on your opportunity to get the latest team gear and
        apparel!
      </p>
      <p>
        All items include the Haverhill Hammers logo. Perfect for your favorite
        hammer to wear during practice or when we're on the road competing in
        tournaments.
      </p>
      <p>
        This gear isn't just for your little hammer. This is a great way for the
        entire family to show their support, and our team's presence, while we
        compete
      </p>
      <p>
        Offered through
        <a href="http://treasuretees.com" target="_blank">
          Treasure Tees
          <font-awesome-icon
            :icon="['fas', 'external-link-alt']"
            class=""
            size="xs"
          /> </a
        >, a portion of each sale will be donated back to our program!
      </p>
      <p>Happy shopping!</p>
    </div>

    <team-store-button />
  </content-box>
</template>

<script>
import seasonFunctions from '@/commons/seasonFunctions'

import teamStoreButton from '@/components/teamStoreButton'
import contentBox from '@/components/contentBox'

export default {
  name: 'teamStoreOpen',
  components: { teamStoreButton, contentBox },
  computed: {
    title() {
      return seasonFunctions.toString() + ' Team Store'
    },
  },
}
</script>

<style scoped></style>
